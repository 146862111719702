import React, { Fragment, useEffect } from 'react'
import Img from 'gatsby-image'
import { graphql, Link } from 'gatsby'
import classNames from 'classnames'

import SEO from '../../components/Seo'
import RelatedItems from '../../components/RelatedItems/RelatedItems'
import ContactUs from '../../components/Contactus/Contactus'
import Icon from '../../components/UI/Icon/Icon'
import KeywordList from '../../components/KeywordList'
import { mergeArrays } from '../../utils/mergeArrays'
import { isArrayExist, addClassName } from '../../utils/utility'

const masmovil = ({ data }) => {
  const {
    project: {
      frontmatter: {
        title,
        characteristics,
        descriptions,
        advertising,
        outcome,
        feedbacks,
        interestingForYou,
        seo
      },
      html
    },
    relatedItems: { edges },
    image,
    advertisingImage,
    outcomeImage
  } = data

  /* added order to related item, will use it to
    show correct orders on Intresting For You section */
  const itemsList = interestingForYou
    .map((item, index) => Object.assign({ order: index }, item))

  // created array of objects which will be shown on Interesting for you section
  const intrestingForYouContent = mergeArrays(edges, itemsList)

  const orderedCharacteristics = Object.keys(characteristics)
    .map(key => ({ key, ...characteristics[key] }))
    .sort((a, b) => a.order - b.order)

  useEffect(() => {
    addClassName('gatsby-resp-image-wrapper')
  }, [])

  return (
    <Fragment>
      <SEO data={ seo } />
      <div className="aeternity-blockchain-platform single__project">
        <div className="project__header wrapper">
          <h1 className="project__title">{title}</h1>
        </div>
        <div className="project__subheader">
          <div className="project__characteristics">
            {orderedCharacteristics.map(({ key, order, title, details }, index) => {
              return (
                <div key={order} className={classNames(
                  'characteristic__item',
                  { 'characteristic__item--last': (index + 1) % 3 === 0 }
                )}>
                  <Icon type={key} />
                  <div className="characteristic__info">
                    <p className="characteristic__title">{title}</p>
                    <div className="characteristic__details">
                      {Array.isArray(details)
                        ? (
                          <KeywordList
                            list={details}
                            keyword={key}
                          />
                        )
                        : details
                      }
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          <Img fluid={image.childImageSharp.fluid} className="project__image" />
        </div>
        <div className="project__content wrapper">
          <div className="project__advertising">
            <p className="advertising__text">{advertising}</p>
            <Img fixed={advertisingImage.childImageSharp.fixed} className="advertising__image" />
          </div>
          <div className="project__descriptions">
            {descriptions.map((description, i) => {
              return (
                <div key={i} className={classNames(
                  'description__item',
                  { 'description__item--isLast': i === descriptions.length - 1 }
                )}>
                  <p className="description__title">{description.title}</p>
                  <p
                    className="description__text general-link-style"
                    dangerouslySetInnerHTML={{ __html: description.text }} />
                </div>
              )
            })}
          </div>
        </div>
        <div className="wrapper">
          <div className="project__contact">
            <p className="contact__text">Let's turn your idea into reality</p>
            <Link to="/contact-us/" className="contact__button">Talk to us</Link>
          </div>
        </div>
        <div className="project__solutions wrapper">
          <div
            className="solutions__text"
            dangerouslySetInnerHTML={{ __html: html }} />
        </div>
        <div className="project__outcome">
          <p className="outcome__title">Outcome</p>
          <p
            className="outcome__text"
            dangerouslySetInnerHTML={{ __html: outcome }} />
          <Img fluid={outcomeImage.childImageSharp.fluid} className="outcome__image" />
        </div>
        <div className="project__feedbacks wrapper">
          <div className="feedbacks__quotes">
            <Icon type="quotes" />
          </div>
          {isArrayExist(feedbacks) && feedbacks.map((feedback, i) => (
            <div
              key={i}
              className={classNames(
                'feedback__item',
                { 'feedback__item--isLast': i === feedbacks.length - 1 }
              )}>
              <p className="feedback__author">
                {feedback.author}
              </p>
              <p className="feedback__author-position">
                {feedback.position}
              </p>
              <p className="feedback__text">
                {feedback.text}
              </p>
            </div>
          )
          )}
        </div>
        <ContactUs
          contactUsDescription={''}
          contactUsSlogan={'Ready to know more about Bluepes?'}
          buttonName={'Contact us '}
        />
        <RelatedItems
          label={'Interesting for you'}
          relatedItems={intrestingForYouContent}
        />
      </div>
    </Fragment>
  )
}

export default masmovil

export const project1 = graphql`
  query ($slug: String!, $relatedItemsTitle: [String]!) {
    project: markdownRemark(
      fields: { slug: { eq: $slug }}
      fileAbsolutePath: { regex: "/content/individual-pages/projects/" }
    ) {
      html
      frontmatter {
        title
        characteristics {
          location {
            order
            title
            details
          }
          teamsize {
            order
            title
            details
          }
          industries {
            order
            title
            details
          }
          expertise {
            order
            title
            details
          }
          technologies {
            order
            title
            details
          }
          services {
            order
            title
            details {
              name
              redirectLink
            }
          }
        }
        advertising
        descriptions {
          title
          text
        }
        outcome
        feedbacks {
          author
          position
          text
        }
        interestingForYou {
          name
          description
          image {
            childImageSharp {
              fixed (width: 394, height: 237, quality: 100) {
                src
              }
            }
          }
          type
        }
        seo {
          canonical
          title
          description
          keywords
          robots
          image {
            childImageSharp {
              original {
                src
              }
            }
          }
          imageAlt
          twitterCard
        }
      }
    }
    relatedItems: allMarkdownRemark(
      limit: 3
      filter: {
        frontmatter: { permalink: { in: $relatedItemsTitle }}
        fileAbsolutePath: { regex: "/content/individual-pages/"}
      }
    ) {
      edges {
        node {
          excerpt(pruneLength: 300)
          frontmatter {
            permalink
            title
            author
            authorImage {
              childImageSharp {
                fixed (base64Width: 100){
                  base64
                }
              }
            }
            whatWeDo
            date(formatString: "DD MMM YYYY")
            image {
              childImageSharp {
                fixed (width: 394, height: 237, quality: 100){
                  src
                }
              }
            }
            description
          }
          fields {
            slug
            type
          }
        }
      }
    }
    image: file(relativePath: { eq: "images/aeternity-blockchain-platform-main.png" }) {
      childImageSharp {
        fluid (quality: 85) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    advertisingImage: file(relativePath: { eq: "images/aeternity-advertising-image.png" }) {
      childImageSharp {
        fixed (width: 328, quality: 100) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    outcomeImage: file(relativePath: { eq: "images/aeternity-outcome-image.jpg" }) {
      childImageSharp {
        fluid (quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
