import React from 'react'

import { slugify } from '../utils/utilityFunctions'
import { isObject } from '../utils/utility'

const KeywordList = ({ list, keyword }) => {
  return keyword === 'services'
    ? (list.map((elem, i) => (
      <a
        key={i}
        className="characteristic__link"
        href={isObject(elem) && elem.redirectLink
          ? `/${ keyword }/${ slugify(elem.redirectLink) }/`
          : null
        }
      >
        {`${ elem.name }${ i === list.length - 1 ? '' : ', ' }`}
      </a>
    ))) : list.join(', ')
}

export default KeywordList
